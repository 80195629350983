import React from 'react'
import { ThemeProvider } from 'styled-components'
//STYLES
import { GlobalStyle } from '../utils/globalStye'
import { theme } from '../utils/theme'
//DATA
import { info } from '../assets/data/kontakt'
//COOKIES
import Cookie from '../components/cookie/Cookie'
//NAVBAR
import Navbar from '../components/navbar/Navbar_Container'
import NavbarHeader from '../components/navbar/NavbarHeader_IKSdruk'
//FOOTER
import Footer from '../components/footer/footer/footer'
import FooterHeader from '../components/footer/footerHeader/FooterHeader_Card'
import FooterIks from '../components/footer/footerIks/FooterIksTarcza'

const Layout = (props) => {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div>
        <NavbarHeader
          tel={info.tel}
          email={info.mail} />
        <Navbar />!
        <div>{props.children}</div>
        <Cookie />
        <FooterHeader
          heading={footer.heading}
          subHeading={footer.subHeading}
          bgColor={theme.color.grey5}
          hrOpacity='1' />
        <Footer
          bgColor={theme.color.grey5}
          tel={info.tel}
          email={info.mail}
          ulica={info.ulica}
          nrBud={info.nrBud}
          miasto={info.miasto}
          kod={info.kod}
          woj={info.woj}
          kraj={info.kraj}
        />
        <FooterIks
          bgColor={theme.color.grey5} />
      </div>
    </ThemeProvider>

  )
}
export default Layout

export const footer = {
  heading: 'Zadzwoń do nas już dziś!',
  subHeading: 'Plakaty? Ulotki? Wzytówki? Na dziś? A może potrzebujesz projektu?\nZ przyjemnością odpowiemy na wszystkie pytania.',
}