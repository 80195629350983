import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
//STYLES
import { H1, H4, H2, H5, LabelBW, Text } from "../../utils/typo"
import { ContainerMob, CardWrapper, Section } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const NavbarInfo = styled.div`
    
    background-color: ${props => props.bgColor || props.theme.color.grey8};

    ${down('sm')} {
        display: none;
    }
    > div {
        padding: 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    p {
        font-weight: 400;
        font-family: 'Roboto Condensed';
        color: ${props => props.fontColor || props.theme.color.font};

        span {
            font-weight: 700;
            color: ${props => props.spanColor || props.theme.color.base1};
        }
    }

    a {
        font-size: 1.7rem;
        margin-right: 1rem;

        &:hover {
            color: ${props => props.spanColor || props.theme.color.base1};
        }
    }
`;

const NavbarHeader = (props) => {
    return (
        <NavbarInfo bgColor={props.bgColor}>
            <ContainerMob>
                <div>
                    <a
                        target='blank'
                        href='https://www.facebook.com/iksdruk'>
                        <FontAwesomeIcon
                            icon={faFacebook} />
                    </a>
                    <a
                        target='blank'
                        href='https://www.instagram.com/iks.druk/'>
                        <FontAwesomeIcon
                            icon={faInstagram} />
                    </a>
                </div>

                <div>
                    <Text textAlign='right' mr='2rem'>{props.email}</Text>
                    <Text textAlign='right' mr='2rem'>+48 {props.tel}</Text>
                </div>

            </ContainerMob>
        </NavbarInfo>
    )
}
export default NavbarHeader