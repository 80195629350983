import { Link } from 'gatsby'
import React from 'react'
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components'

const variantOptions = {
    white: {
        bgColor: props => props.theme.color.white,
        arrow: props => props.theme.color.base1,
        colorFont: props => props.theme.color.base1,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
    lightGrey: {
        bgColor: props => props.theme.color.grey2,
        arrow: props => props.theme.color.sec1,
        colorFont: props => props.theme.color.prim2,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
    light: {
        bgColor: props => props.theme.color.base4,
        arrow: props => props.theme.color.white,
        colorFont: props => props.theme.color.white,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
    base: {
        bgColor: props => props.theme.color.base1,
        arrow: props => props.theme.color.white,
        colorFont: props => props.theme.color.white,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
    dark: {
        bgColor: props => props.theme.color.sec3,
        arrow: props => props.theme.color.white,
        colorFont: props => props.theme.color.white,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
    extraDark: {
        bgColor: props => props.theme.color.prim1,
        arrow: props => props.theme.color.white,
        colorFont: props => props.theme.color.white,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
    important: {
        bgColor: props => props.theme.color.sec1,
        arrow: props => props.theme.color.base5,
        colorFont: props => props.theme.color.white,
        bgColorHover: props => props.theme.color.grey4,
        arrowHover: props => props.theme.color.font,
        colorFontHover: props => props.theme.color.font,
    },
};

const Arrow = styled.svg`
    stroke: ${props => props.arrow || props.theme.color.sec1};
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    transition: all ease-in-out .7s;
`;

const Card = styled(Link)`
    ${down('sm')} {
    display: none;
  }
    position: relative;
    background-color: ${props => props.bgcolor || props.theme.color.baseExtraLight};
    transition: all ease-in-out .7s;
    
    p, h3 {
        line-height: 1.2;
        color: ${props => props.colorfont || props.theme.color.baseExtraLight};
        transition: all ease-in-out .7s;
    }

    p {
        margin-top: 1rem;
    }
    
    ${({ variant }) =>
        variant &&
        variantOptions[variant] &&
        css`
        background-color: ${variantOptions[variant].bgColor};

        p, h3 {
            color: ${variantOptions[variant].colorFont};
            }
        ${Arrow} {
            stroke: ${variantOptions[variant].arrow};
        }

        :hover {
            background-color: ${variantOptions[variant].bgColorHover};

            p, h3 {
                color: ${variantOptions[variant].colorFontHover};
            }

            ${Arrow} {
                stroke: ${variantOptions[variant].arrowHover};
            }
        }
   `}
`;

const Ratio = styled.canvas`
    width: 100%;
    margin: 0;
    display: block;
`;

const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 2rem;
`;

class SquareCard extends React.Component {

    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return (
            <Card
                variant={this.props.variant}
                bgcolor={this.props.bgcolor}
                colorfont={this.props.colorfont}
                data-sal="slide-down"
                data-sal-delay={this.props.delay}
                data-sal-duration='400'
                to={this.props.link || '/'}
            >
                <Ratio width="1" height="1" />
                <Content>
                    <h3>
                        {this.props.name}
                    </h3>
                    <p>
                        {this.props.opis}
                    </p>

                    <Arrow
                        arrow={this.props.arrow}
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px" y="0px"
                        width="40px" height="40px"
                        viewBox="0 0 85 85"
                    >
                        <g>
                            <polyline
                                fill="none"
                                strokeWidth="10"
                                strokeMiterlimit="10"
                                points="10,80 80,80 80,10" />
                            <line
                                fill="none"
                                strokeWidth="10"
                                strokeMiterlimit="10"
                                x1="80"
                                y1="80"
                                x2="5"
                                y2="5"
                            />
                        </g>
                    </Arrow>


                </Content>

            </Card>
        )
    }
}
export default SquareCard