import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
//DATA
import { list } from '../../assets/data/pageStructure'
//STYLED
import { Container } from '../../utils/utils'
import { H1 } from '../../utils/typo'
//COMPONENTS
import NavMobile from './navbarMobile/NavMobile';
import HamburgerMenuIcon from './navbarMobile/HamburgerMenuIcon';
import LogoMobile from './navbarMobile/LogoMobile'

const navbarItemCount = Object.keys(list).length;
const navbarHeight = '60px';

const Nav = styled.nav`
    position: sticky;
    top: 0;
    z-index: 2;
    height: ${navbarHeight};
    width: 100%;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    //box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
    background-color: rgba(255, 255, 255, 1);
    ${down('sm')} {
        display: none;
    }
`;

const NavbarWrapper = styled(Container)`
    display: flex;
    justify-content: space-between;
`;

const NavbarItemWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(${navbarItemCount}, minmax(100px, 1fr));
    justify-self: center;
`;

const NavbarItem = styled(Link)`
    text-align: center;
    padding: 0 1rem;
    display: grid;
    align-items: center;
    width: 100%;
    white-space: pre-wrap;

    background-color: transparent;
    color: ${({ theme }) => theme.color.font};
    font-size: 18px;
    font-family: 'Roboto Condensed';
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.1;
    border-style: solid;
    border-width: 0 0 8px 0;
    border-color: transparent;
    transition: all .5s;

    &.active,
    :hover {
        color: ${({ theme }) => theme.color.base1};
        border-color: ${({ theme }) => theme.color.base1};
    }
`;

const Logo = styled(Link)`
    padding: 0 0 0 0;
    display: flex;
    height: ${navbarHeight};
    div {
        height: 100% !important;
    }
    p {
        line-height: ${navbarHeight};
        }
`;

class Navbar extends React.Component {

    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        const menu = list.map(item => (
            <NavbarItem
                activeClassName="active"
                key={item.name}
                to={item.path}>{item.name}
            </NavbarItem>
        ))

        return (<>
            {/*BELKA NAWIGACJI*/}
            <Nav id="menu"
                role="navigation"
                aria-label="main navigation">

                <NavbarWrapper>
                    <Logo to="/">
                        <StaticImage
                            src='../../assets/images/logo/logo.png'
                            alt='Logo'
                            layout='constrained'
                            objectFit='contain'
                            placeholder='blurred'
                            height={50}
                        />
                        <H1
                            as='p'
                            ml='.7rem'>
                            DRUK
                        </H1>
                    </Logo>

                    <NavbarItemWrapper>
                        {menu}
                    </NavbarItemWrapper>

                </NavbarWrapper>

            </Nav >

            {/*LOGO MOBILNE*/}
            <LogoMobile />

            {/*MENU MOBILNE*/}
            {/* <motion.div
                animate={this.state.clicked ? "open" : "closed"}
                variants={variants}>

                <NavMobile>
                    {menu}
                </NavMobile>
            </motion.div> */}

            {this.state.clicked ? (
                <NavMobile top='0' opacity='1'>
                    {menu}
                </NavMobile>
            ) : (
                <NavMobile>

                </NavMobile>
            )}

            {/*IKONKA HAMBURGER MENU POJAWIAJĄCE SIĘ TYLKO NA MOBILNYCH*/}
            <HamburgerMenuIcon
                onClick={this.handleClick}
                state={this.state.clicked} />
        </>
        )
    }
}
export default Navbar